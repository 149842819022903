@import "../../../styles/variables.scss";
@import "../../../styles/_media-queries.scss";

.header {
  background-color: $color-light-gray;

  &__logo {
    display: flex;
    align-items: center;

    img {
      height: 5rem;
      width: auto;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }

  &__title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    h1 {
      margin: 0;
      font-size: 2rem;
      color: $color-dark-gray;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .user__dropdown {
    position: relative;

    .user__button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;

      img {
        object-fit: cover;
        height: 3rem;
        width: 3rem;
        margin-right: 0.5rem;
      }

      img.premium__icon {
        margin-top: 0.5rem;
      }
    }

    .dropdown__menu {
      position: absolute;
      right: 0;
      top: 4rem;
      background: $color-light-gray;
      border: 1px solid $color-gray;
      border-radius: 1rem;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      padding: 0.25rem;
      min-width: 15rem;
      width: 100%;
      max-width: 15rem;
      text-align: center;

      p {
        margin: 0;
        padding: 0.5rem 0;
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: 0.25rem;
        border-bottom: 1px solid $color-gray;
        color: $color-dark-gray;
      }

      button {
        cursor: pointer;
        width: 100%;
        padding: 0.5rem 0;
        border-radius: 1rem;
        border: none;
        background-color: $color-light-gray;

        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        transition: all 0.3s ease;

        &:hover {
          color: $color-light-gray;
          background-color: $color-blue;
        }
      }

      .logout__button {
        color: $color-error;
        margin: 0.25rem 0;
        border: 1px solid $color-error;

        &:hover {
          background-color: $color-error;
        }
      }

      .admin__button {
        color: $color-blue;
        margin: 0.25rem 0;
        border: 1px solid $color-blue;

        &:hover {
          background-color: $color-blue;
        }
      }

      .login__button {
        color: $color-blue;

        &:hover {
          background-color: $color-blue;
        }
      }
    }
  }

  // .premium-user {
  //   padding: 0.5rem;
  //   border-radius: 0.5rem;

  //   p {
  //     display: flex;
  //     align-items: center;

  //     .premium-icon {
  //       margin-left: 0.5rem;
  //       height: 1rem;
  //     }
  //   }
  // }
}
