// src/components/Modal/Modal.scss
@import "../../../styles/variables.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: $color-light-gray;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
    color: $color-black;
  }

  p {
    margin-bottom: 2rem;
    color: $color-black;
  }

  .modal-actions {
    display: flex;
    justify-content: space-between;

    button {
      background-color: $color-blue;
      color: white;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        background-color: darken($color-blue, 10%);
      }

      &:first-of-type {
        background-color: $color-gray;

        &:hover {
          background-color: darken($color-gray, 10%);
        }
      }
    }
  }
}
