@import "../../../styles/variables.scss";

.admin-companies-page {
  padding: 1.25rem; //
  background-color: $color-light-gray;
  border-radius: 0.5rem; //
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 1.5rem; //
    margin-bottom: 1.25rem; //
    text-align: center;
  }

  & > button {
    margin-bottom: 1.25rem;
    background-color: $color-blue;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    &:hover {
      background-color: darken($color-blue, 10%);
    }
  }

  .company-list {
    position: relative;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;

    .company-item {
      background-color: #fff;
      border-radius: 0.5rem; //
      padding: 0.9375rem; //
      margin-bottom: 0.9375rem; //
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1); // 0

      .company-details {
        flex: 1;
        margin-right: 1.25rem; //

        h3 {
          margin: 0 0 0.625rem; //
          font-size: 1.125rem; //
        }

        p {
          margin: 0 0 0.3125rem; //
          color: #666;
        }

        a {
          color: #007bff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .company-actions {
        display: flex;
        gap: 1rem;

        button {
          padding: 0.5rem 0.75rem; //
          border: none;
          border-radius: 0.25rem; //
          cursor: pointer;
          font-size: 0.875rem; //

          &:hover {
            opacity: 0.8;
          }

          &:first-child {
            background-color: #007bff;
            color: #fff;
          }

          &:last-child {
            background-color: #dc3545;
            color: #fff;
          }
        }
      }
    }
  }

  .upload-section {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem; //
    gap: 0.625rem; //

    input[type="text"] {
      padding: 0.625rem; //
      border-radius: 0.25rem; //
      border: solid #ddd;
      flex: 1;
    }

    button {
      padding: 0.625rem 1.25rem; //
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 0.25rem; //
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .pending-uploads {
    margin-top: 1.25rem; //

    h3 {
      font-size: 1.125rem; //
      margin-bottom: 0.625rem; //
    }

    .pending-upload-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        background-color: #fff;
        border-radius: 0.5rem; //
        padding: 0.625rem; //
        margin-bottom: 0.625rem; //
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1); // 0

        button {
          background-color: #dc3545;
          color: #fff;
          border: none;
          border-radius: 0.25rem; //
          padding: 0.5rem 0.75rem; //
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    button {
      padding: 0.625rem 1.25rem; //
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 0.25rem; //
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .company-form {
    display: flex;
    flex-direction: column;
    gap: 0.625rem; //
    margin-top: 1.25rem; //
    padding-bottom: 1rem;

    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 0.875rem; //
      color: #333;

      input,
      textarea {
        width: 100%;
        padding: 0.5rem; //
        margin-top: 0.3125rem; //
        border-radius: 0.25rem; //
        border: 1px solid #ddd;
      }

      textarea {
        resize: vertical;
      }
    }
  }
}
