@import "../../../styles/variables";

$dim: 2rem;

.page_loader_container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  .page_loader {
    width: $dim;
    height: $dim;
    position: relative;
    animation: spin988 2s linear infinite;

    .circle {
      $color: $color-gray;
      $dim: 0.8rem;
      width: $dim;
      height: $dim;
      background-color: $color;
      border-radius: 50%;
      position: absolute;

      &:nth-child(1) {
        top: 0;
        left: 0;
      }

      &:nth-child(2) {
        top: 0;
        right: 0;
      }

      &:nth-child(3) {
        bottom: 0;
        left: 0;
      }

      &:nth-child(4) {
        bottom: 0;
        right: 0;
      }
    }
  }

  @keyframes spin988 {
    0% {
      transform: scale(1) rotate(0);
    }

    20%,
    25% {
      transform: scale(1.3) rotate(90deg);
    }

    45%,
    50% {
      transform: scale(1) rotate(180deg);
    }

    70%,
    75% {
      transform: scale(1.3) rotate(270deg);
    }

    95%,
    100% {
      transform: scale(1) rotate(360deg);
    }
  }
}
