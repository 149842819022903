// src/components/Documents/SearchComponent.scss
@import "../../../styles/variables.scss";

.search-component {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  border-radius: 2rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  padding: 0.75rem 1rem;
  background-color: #fff;
  .search-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      background-color: transparent;
      width: 80%;
      border: none;

      font-size: 1rem;
      color: $color-gray;

      &::placeholder {
        color: $color-gray;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
