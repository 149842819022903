@import "../../../styles/variables.scss";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
  font-size: 1.4rem;
  font-weight: 600;
  p {
    font-size: 1rem;
    a {
      text-decoration: none;
      color: $color-blue;
      font-weight: 700;
    }
  }
}
