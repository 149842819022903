.law-companies-page {
  margin: 0 2rem;
  margin-top: 1rem;
  padding: 2rem;
  border-radius: 0.5rem;
  ul {
    list-style: none;
  }

  h2 {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
  }

  .law-companies-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
  }

  .law-company-card {
    background: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;

    h3 {
      margin-top: 0;
    }

    .company-description {
      margin: 1rem 0;
      color: #555;
    }

    p {
      margin: 0.5rem 0;
    }
    a {
      color: #2461a2;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
