@import "../../../styles/variables.scss";

.admin-page {
  display: flex;
  justify-content: space-evenly;
  button {
    width: 100%;
    max-width: 200px;
    cursor: pointer;
    border: none;
    display: block;
    padding: 2rem;
    border-radius: 2rem;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    color: $color-black;
    background-color: $color-light-gray;
    transition: background-color 0.3s, color 0.3s;

    &.active,
    &:hover {
      background-color: $color-blue;
      color: white;
    }
  }
}
