@import "./../../../styles/variables.scss";
.admin-documents-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
  .upload-section,
  .search-section {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    input[type="text"],
    select {
      display: block;
      margin-top: 1rem;
    }

    input[type="text"] {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      border: none;
      width: 100%;
      max-width: 20rem;
      font-size: 1rem;
      outline: none;
    }
    button {
      background-color: $color-blue;
      color: white;
      border: none;
      border-radius: 0.5rem;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      &:hover {
        background-color: darken($color-blue, 10%);
      }
    }
  }

  .upload-section {
    gap: 1rem;
  }

  .pending-uploads {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 2rem;
  }

  .pending-upload-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 2rem;
    list-style: none;
    width: 100%;
    max-width: 50rem;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      width: 100%;
    }
  }

  .document-sections {
    position: relative;
    display: flex;
    gap: 2rem;
    padding-top: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .document-section {
    width: 100%;
    max-width: 50rem;
    background-color: $color-light-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    & > button {
      margin-top: 1rem;
      background-color: $color-blue;
      color: white;
      border: none;
      border-radius: 0.5rem;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      &:hover {
        background-color: darken($color-blue, 10%);
      }
    }
    h2 {
      text-align: center;
    }
    .document-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      list-style: none;
      padding: 0;
      li {
        padding: 0.5rem 1rem;
        background-color: white;
        border-radius: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.5rem 0;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        button {
          background: red;
          color: white;
          border: none;
          border-radius: 0.5rem;
          padding: 0.5rem;
          cursor: pointer;
          &:hover {
            background: darken(red, 10%);
          }
        }
      }
    }
  }
}
