@import "../../../styles/variables";

.language-switcher {
  display: flex;
  align-items: center;

  .language-select {
    appearance: none;
    border: 1px solid $color-gray;
    border-radius: 0.5rem;
    background-color: $color-light-gray;
    color: $color-black;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: none;

    &:hover {
      border-color: $color-blue;
    }

    &:focus {
      outline: none;
      border-color: $color-blue;
    }

    &:active {
      outline: none;
      border-color: $color-blue;
    }
  }
  option {
    background-color: $color-light-gray;
    color: $color-black;
    cursor: pointer;

    &:hover {
      background-color: $color-blue;
    }
  }
}
