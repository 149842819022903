// src/styles/App.scss
@import "./styles/variables.scss";
@import "./styles/_media-queries.scss";

* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: lighten($color-gray, 20%) darken($color-light-gray, 5%);
}

a {
  text-decoration: none;
  color: $color-black;
}

body {
  color: $color-black;
  font-size: 1rem;
  position: relative;
}

.main-content {
  background: $background-gradient, url("assets/images/network.png") no-repeat right center;
  min-height: calc(100vh - 11rem);
  padding: 2rem 0;
  display: flex;
  align-items: center;
}

.main-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.content {
  position: relative;
  width: 100%;
  height: 35rem;
  max-width: calc(100% - 35rem);
  border-radius: 1rem;
  overflow: auto;
  background-color: $color-light-gray;
  @media #{$screen-md} {
    margin-left: 0;
    padding: 1rem;
  }
}
.container {
  max-width: $container-max-width-xs;
  margin: 0 auto;
  width: 100%;
  padding: 0 1rem;

  @media #{$screen-xs} {
    max-width: $container-max-width-sm;
  }

  @media #{$screen-sm} {
    max-width: $container-max-width-md;
  }

  @media #{$screen-md} {
    max-width: $container-max-width-lg;
  }

  @media #{$screen-lg} {
    max-width: $container-max-width-xl;
  }

  @media #{$screen-xl} {
    max-width: $container-max-width-xxl;
  }
}

@media #{$max-screen-lg} {
  .main-layout {
    flex-direction: column;
  }
  .sidebar {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .content {
    max-width: 100%;
  }
}

@media #{$screen-md} {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar ul {
    display: flex;
    justify-content: space-around;
  }
}

@media #{$screen-xs} {
  .header {
    flex-direction: column;
    text-align: center;
  }
  .sidebar ul {
    flex-direction: column;
  }
}
