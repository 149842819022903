@import "../../../styles/variables.scss";
@import "../../../styles/_media-queries.scss";

.sidebar {
  background-color: transparent;
  padding: 1rem;
  height: 100%;

  ul {
    list-style: none;
    padding: 0;
    max-width: 350px;
    width: 100%;
    gap: 1.5rem;

    li {
      a {
        display: block;
        padding: 2rem;
        border-radius: 2rem;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 600;
        color: $color-black;
        background-color: $color-light-gray;
        transition: background-color 0.3s, color 0.3s;

        &.active,
        &:hover {
          background-color: $color-blue;
          color: white;
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    ul {
      display: flex;
      justify-content: space-around;

      li {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 480px) {
    ul {
      flex-direction: column;

      li {
        margin-bottom: 1rem;
      }
    }
  }
}
