@import "../../../styles/variables.scss";
@import "../../../styles/_media-queries.scss";

.login-page {
  display: inline-block;
  margin-top: 4rem;

  &__content {
    max-width: 25rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;

    .error-message {
      color: red;
      text-align: center;
    }
  }
  .login-form {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-bottom: 1rem;
      color: $color-black;
    }

    label {
      display: block;
      margin-bottom: 1rem;
      color: $color-black;
      text-align: center;
    }

    label + input {
      max-width: 15rem;
      padding: 1rem 0.75rem;
      margin-top: 0.5rem;
      border: 2px solid lighten($color-gray, 20%);
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    label + input::placeholder {
      color: $color-black;
      font-weight: 500;
    }

    label + input::focus {
      border: 2px solid $color-blue;
    }

    button {
      background-color: $color-blue;
      color: white;
      padding: 1rem 2rem;
      border: none;
      border-radius: 1rem;
      font-size: 1.25rem;
      font-weight: 700;
      cursor: pointer;

      &:hover {
        background-color: lighten($color-blue, 10%);
      }
    }

    p {
      margin-top: 1rem;
      color: $color-black;
      font-size: 0.875rem;

      a {
        color: $color-blue;
        text-decoration: none;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
